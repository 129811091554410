
<template>
  <section
    class="box-height pendingApproval scollDom"
    style="padding-bottom: 20px; max-height: calc(100vh - 70px)"
  >
    <div style="background-color: #fff; padding-top: 20px">
      <div style="padding-bottom: 20px" class="clearfix">
        <el-pagination
          style="margin-top: 10px; float: none; text-align: right"
          :hide-on-single-page="pagination.total > 10 ? false : true"
          v-if="pagination.total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.pageNo"
          :page-sizes="[10, 20, 50, 100]"
          :pager-count="5"
          :page-size="pagination.pageSize"
          layout="total,prev, pager, next,sizes"
          :total="pagination.total"
        ></el-pagination>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-table
              :header-cell-style="{
                'background-color': '#f5f7fa',
              }"
              :data="ListData"
              class="tabBorder"
              v-loading="listLoading"
              ref="handSelectTest_multipleTable"
            >
              <el-table-column
                prop="content"
                label="待审批事项"
                align="left"
                min-width="600px"
              >
                <template slot-scope="scope">
                  <div style="display: flex; align-items: center">
                    <p
                      class="tabHref"
                      :title="scope.row.content"
                      style="
                        width: 85%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        cursor: pointer;
                      "
                      @click="handleCheck(scope.row)"
                    >
                      {{ scope.row.content }}
                    </p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                :show-overflow-tooltip="true"
                prop="createdTime"
                label="流程到达时间"
                min-width="120"
              ></el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-pagination
          :hide-on-single-page="pagination.total > 10 ? false : true"
          v-if="pagination.total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.pageNo"
          :page-sizes="[10, 20, 50, 100]"
          :pager-count="5"
          :page-size="pagination.pageSize"
          layout="total,prev, pager, next,sizes"
          :total="pagination.total"
        ></el-pagination>
      </div>
    </div>
    <!-- 审核 -->
    <el-drawer
      :visible.sync="approvalDrawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleCloseApproval"
      destroy-on-close
    >
      <div slot="title" class="drawerTitle">{{ detailTit }}</div>
      <div class="drawerCon">
        <noComtractApporval
          ref="noComtractApporval"
          v-if="detailTit == '无合同开票申请审批' && approvalDrawerVisible"
          :id="relativeId"
          @close="handleCloseApproval"
        ></noComtractApporval>
        <aothers
          ref="aothers"
          :row="kpiRow"
          v-if="detailTit == '消息详情' && approvalDrawerVisible"
          @close="handleCloseApproval"
        ></aothers>
        <contractInfo
          v-if="detailTit == '合同详情' && approvalDrawerVisible"
          :contractId="relativeId"
          :activeName="activeName"
          @close="handleCloseApproval"
        ></contractInfo>
      </div>
    </el-drawer>
    <!-- 审核 -->
    <el-drawer
      :visible.sync="isfinancialTask"
      append-to-body
      :modal="false"
      custom-class="drawerClass"
      :close-on-click-modal="false"
      direction="ttb"
      :before-close="handleClose1"
    >
      <div slot="title" class="drawerTitle">
        <div><i class="drawIcon iconfont icon-pl_xb"></i>{{ tit }}</div>
      </div>
      <!-- 发喜报-->
      <div class="drawerCon">
        <happyNews
          v-if="isfinancialTask"
          ref="saveHappyNews"
          inpath="happyNewsApproval"
          :projectResumeId="projectResumeId"
          @getisApproval="getisApproval"
          @loadList="getPageList"
          @closeThis="handleClose1()"
        ></happyNews>
        <div class="drawerCon_footer fixedFoot" v-if="isapproval">
          <el-button
            type="default"
            plain
            size="mini"
            @click="rejectedHappyNews()"
            >驳回</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="saveInfos"
            style="margin-right: 15px"
            >确认并提交</el-button
          >
        </div>
      </div>
    </el-drawer>
  </section>
</template>
<script>
import {
  commonApprovalList, //当前用户待审批列表
  offerGoodnewsApprovalReject, //驳回
} from "../api/api";
import happyNews from "../components/happyNews";
import noComtractApporval from "../components/tools/noComtractApporval";
import aothers from "../components/message/others";
import contractInfo from "../components/contract/contractInfo";
export default {
  name: "pendingApproval",
  components: {
    happyNews,
    noComtractApporval,
    aothers,
    contractInfo,
  },
  data() {
    return {
      listLoading: false,
      pagination: {
        total: 0,
        pageNo: 1,
        pageSize: 50,
      },
      ListData: [],
      isfinancialTask: false,
      projectResumeId: null,
      tit: "喜报审核",
      approvalDrawerVisible: false,
      isapproval: false,
      detailTit: "",
      activeName: "待审批",
    };
  },
  created() {
    this.getPageList();
  },
  methods: {
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.pagination.pageSize = val;
      this.pagination.pageNo = 1;
      this.getPageList();
    },
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      this.pagination.pageNo = val;
      this.getPageList();
    },
    //列表数据
    getPageList() {
      window.scrollTo(0, 0);
      this.listLoading = true;
      commonApprovalList(this.pagination).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.pagination.total = res.result.total;
          this.ListData = res.result;
        }
        this.listLoading = false;
      });
    },
    //查看审批
    handleCheck(row) {
      this.relativeId = row.relativeId;
      if (row.type == "无合同开票消息") {
        this.detailTit = "无合同开票申请审批";
        this.approvalDrawerVisible = true;
      } else if (row.type == "合同消息") {
        this.detailTit = "合同详情";
        this.approvalDrawerVisible = true;
        // this.$router.push({
        //   path: "/contracts",
        //   query: { objectId: row.relativeId },
        // });
      } else if (row.type == "喜报消息") {
        this.projectResumeId = row.relativeId;
        this.isfinancialTask = true;
      }
    },
    handleCloseApproval() {
      this.approvalDrawerVisible = false;
      this.pagination.pageNo = 1;
      this.getPageList();
    },
    //驳回
    rejectedHappyNews() {
      offerGoodnewsApprovalReject(this.projectResumeId).then((res) => {
        if (res.success) {
          this.$message.success("驳回成功");
          this.isfinancialTask = false;
          this.pagination.pageNo = 1;
          this.getPageList();
        }
      });
    },
    //判断是否审核
    getisApproval(data) {
      if (data == "待审批") {
        this.isapproval = true;
        this.tit = "喜报审核";
      } else {
        this.isapproval = false;
        this.tit = "喜报";
      }
    },
    saveInfos() {
      this.$refs.saveHappyNews
        .saveHappyNewsApproval()
        .then(() => {
          // this.saveloading = false;
          this.handleClose1();
        })
        .catch(() => {
          // this.saveloading = false;
        });
    },
    //喜报弹窗关闭
    handleClose1() {
      this.isfinancialTask = false;
      this.pagination.pageNo = 1;
      this.getPageList();
    },
  },
};
</script>