<template>
  <div class="msgOthers">
    <table
      class="
        el-table
        tabBorder
        el-table--fit el-table--enable-row-hover
        digTable
        specialS
      "
      cellpadding="0"
      cellspacing="0"
    >
      <thead class="has-gutter">
        <tr class="tableTdTrH">
          <th
            v-for="(i, index) in headerOption"
            :key="index"
            :style="
              i == '详情'
                ? 'max-width: 40%'
                : `max-width: ${60 / (headerOption.length - 1)}%`
            "
            scope="col"
          >
            {{ i }}
          </th>
        </tr>
      </thead>
      <tbody>
        <p v-show="isData" style="text-align: center; color: #909399">
          暂无数据
        </p>
        <tr  class="tableTyTrH">
          <td
            v-for="(ie, ins) in iscusoption1"
            :key="ins"
            :style="
              ie.indexOf('commentColumnId') > 0
                ? 'max-width: 40%'
                : `max-width: ${60 / (headerOption.length - 1)}%`
            "
            valign="middle"
          >
            <template v-if="ie.startsWith('[')">
              <pre
                style="
                  line-height: 20px;
                  height: calc(100% - 24px);
                  display: flex;
                  align-items: center;
                "
                :style="
                  ie.indexOf('commentColumnId') > 0
                    ? ''
                    : 'cursor: pointer;color: #2b80ff;'
                "
                v-html="serializeIE(ie)"
                @click="goNav(ie)"
              ></pre>
            </template>

            <template v-else>
              <span
                v-html="internalM(ie, ins)"
                @click="nativalNav(ie, ins)"
                style="display: flex; height: 100%; align-items: center"
              ></span>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
    <el-drawer
      :visible.sync="drawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
    >
      <div slot="title" class="drawerTitle">
        <i
          v-if="drawerTitle == '客户'"
          class="drawIcon iconfont icon-kh_xq"
        ></i>
        <i
          v-if="drawerTitle == '人才'"
          class="drawIcon iconfont icon-rc_bt"
        ></i>
        {{ drawerTitle }}
      </div>
      <div class="drawerCon">
        <customerInfo
          v-if="drawerTitle == '客户' && drawerVisible"
          @toEdit="toEditComInfo"
          :customId="customId"
          :activeToInfo="activedToInfo"
          @loadList="loadList"
        ></customerInfo>
        <resumeDetails
          v-if="drawerTitle == '人才' && drawerVisible"
          :resumeDetailId="customId"
          :hover="hoverInfo"
          :repeatResume="repeatResume"
        ></resumeDetails>
        <addoreEditCus
          v-if="drawerTitle == '编辑客户' && drawerVisible"
          @loadList="loadList"
          @tuComInfo="detailsPop"
          :customId="customId"
        ></addoreEditCus>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  kpiMessageDetail, //其他消息详情
} from "../../api/api";
import customerInfo from "../../components/customer/customerInfo";
import addoreEditCus from "../../components/customer/addorEditCustomer";
import resumeDetails from "../../components/resumeDetail";
export default {
  name: "msgOthers",
  props: ["row"],
  components: { customerInfo, addoreEditCus, resumeDetails },
  data() {
    return {
      resumeDetailId: "",
      drawerVisible: false,
      repeatResume: { type: "列表", valId: "" },
      hoverInfo: { matchAll: true, majorSearch: false, hover: true },
      iscusoption1: [],
      headerOption: [],
      drawerTitle: "",
      customId: "",
      activedToInfo: "1",
      isData:false,
    };
  },
  created() {
    this.getKpiMessageDetail();
  },
  methods: {
    //其他消息详情
    getKpiMessageDetail() {
      kpiMessageDetail({
        relativeId: this.row.relativeId,
        type: this.row.type,
      }).then((res) => {
        if (res.success) {
          this.headerOption = res.result.headers;
          this.iscusoption1 = res.result.rowData;
          if (this.iscusoption1.length < 1) {
            //暂无数据
            this.isData = true;
          } else {
            this.isData = false;
          }
        }
      });
    },
    nativalNav(i, index) {
      const headerText = this.headerOption[index];
      // debugger
      try {
        let _da = JSON.parse(i);
        if (headerText === "候选人") {
          this.drawerTitle = "人才";
          this.drawerVisible = true;
          this.customId = _da.relativeId;
        } else if (headerText === "项目名称") {
          let _router = this.$router.resolve({
            path: "/project/details",
            query: { id: _da.relativeId },
          });
          window.open(_router.href, "_blank");
        } else if (headerText === "客户名称") {
          this.drawerTitle = "客户";
          this.drawerVisible = true;
          this.customId = _da.relativeId;
        }
      } catch (e) {
        return;
      }
    },
    internalM(i, index) {
      let _ie;
      try {
        _ie = JSON.parse(i);
      } catch (e) {
        return i;
      }
      if (Array.isArray(_ie)) {
        return;
      }
      if (_ie.name) {
        return `<a class="aStyle">${_ie.name}</a>`;
      } else {
        return _ie;
      }
    },
    serializeIE(ie) {
      //备注详情字段处理
      try {
        const arr = JSON.parse(ie);
        let str = [];
        if (Array.isArray(arr)) {
          if (ie.includes("commentColumnId")) {
            str = arr.filter((a) => {
              return a.value;
            });
            return str
              .map(
                (a) =>
                  `${a.columnTitle == "详情" ? "" : a.columnTitle + "："}${
                    a.value
                  }`
              )
              .join("\n");
          } else {
            return arr.map((a) => `${a.name}`).join("\n");
          }
        } else {
          // 不是数组就走catch
          throw 1;
        }
      } catch (e) {
        return ie;
      }
    },
    goNav(router) {
      const arr = JSON.parse(router);
      if (Array.isArray(arr)) {
        if (!router.includes("commentColumnId")) {
          return arr.forEach((a) => {
            let _router = this.$router.resolve({
              path: "/resume/details",
              query: { id: a.relativeId },
            });
            window.open(_router.href, "_blank");
          });
        }
      }
    },
    toEditComInfo(id) {
      this.drawerTitle = "编辑客户";
      this.customId = id;
    },
    loadList() {
      if (this.drawerTitle == "编辑客户") {
        this.drawerTitle = "客户";
      }
    },
    detailsPop(id) {
      this.drawerVisible = true;
      this.drawerTitle = "客户";
      this.customId = id; //客户id
      this.activedToInfo = "1";
    },
  },
};
</script>

<style scoped lang="scss">
.msgOthers{
  .tableTdTrH {
  line-height: 36px;
  height: 36px;
  font-weight: bold;
  background: #f5f7fa;
}
.tableTyTrH {
  height: auto;
  td:first-child,
  th:first-child,
  td:nth-child(2),
  th:nth-child(2) {
    background-color: #fff;
  }
}
.fixedLeft {
  td:first-child,
  th:first-child,
  td:nth-child(2),
  th:nth-child(2) {
    position: sticky;
    left: 0; /* 首行永远固定在左侧 */
    z-index: 4;
  }
}
.hasShadow {
  td:nth-child(2),
  th:nth-child(2) {
    left: 120px;
    box-shadow: 5px 0 10px -5px #ddd;
  }
}
.is_fixed {
  position: fixed;
  top: 44px;
  z-index: 9;
}
.kpiSet {
  height: 28px;
  /*padding: 10px 15px;*/
  border: 1px solid #ededed;
  width: 85px;
  line-height: 28px;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  box-sizing: border-box;
  /* margin-left: 15px; */
  display: block;
  float: left;
  color: #606266;
  i {
    /*color: rgb(187, 187, 187);*/
    font-size: 14px;
  }

  &:hover {
    color: #333333;
    border: 1px solid #999999 !important;
  }
}

pre {
  font-family: "Microsoft YaHei", Arial, Helvetica, sans-serif;
  white-space: pre-line;
}

table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}
thead {
  // border-top: 1px solid #ebeef5;
  // border-bottom: 1px solid #ebeef5;
}
thead,
tbody {
  display: block;
}

th {
  padding: 0;
  /*flex: 1;*/
  background-color: rgb(245, 247, 250);
  font-weight: normal;
}

tr {
  height: 50px;
  line-height: 50px;
  /*display: flex;*/
}

td {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
  white-space: nowrap;
}

td,
th {
  display: inline-block;
  width: 150px;
  /*flex: 1;*/
  padding: 0 15px;
}

.digTable tr {
  display: flex;
}

.digTable th,
.digTable td {
  flex: 1;
  /*width: 250px !important;*/
}

.iconshaixuan:hover {
  color: #ff3f3f;
  cursor: pointer;
}

.specialS td {
  // overflow: auto;
  white-space: normal;
}
}
</style>
<style lang="less">
.aStyle {
  cursor: pointer;
  color: #2b80ff;

  &:hover {
    text-decoration: underline;
  }
}

.select_check {
  .el-checkbox {
    display: block;
    margin-bottom: 10px;
  }
  .el-checkbox-group {
    height: 520px;
    overflow-y: auto;
    padding: 10px 15px 0 15px;
  }
}

.diaDiv .el-dialog {
  padding-bottom: 60px;
}

.diaDiv .el-dialog__body {
  border-bottom: none;
}
.showCheckbox {
  .el-checkbox__label {
    color: #606266 !important;
  }
}
</style>
<style lang="scss">
.el-cascader .el-input .el-input__inner {
  height: 32px !important;
}
.configer {
  padding: 0 !important;
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #606266;
  }
  .select_check {
    background: white;
    height: 575px;
    position: relative;
    .kpiButton {
      width: 100%;
      position: absolute;
      bottom: 0;
      right: 10px;
      padding: 10px;
      text-align: right;
      border-top: 1px solid #ededed;
      background: #f0f0f0;
    }
  }
}
.dinput {
  .el-input--suffix .el-input__inner {
    border-radius: 5px 0px 0px 5px;
  }
}
.dinputr {
  border-radius: 0 5px 5px 0;
  border-left: 0;
  .el-input--suffix .el-input__inner {
    border-radius: 0 5px 5px 0;
    border-left: 0;
  }
}
</style>

